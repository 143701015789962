import React from "react";
import {
  Paper,
  Typography,
  Button,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Stack,
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Chip,
  Alert,
  AlertTitle,
  Link,
} from "@mui/material";
import {
  useFirestore,
  useFunctions,
  useFirestoreCollectionData,
} from "reactfire";
import { getApp } from "firebase/app";
import {
  getStripePayments,
  getProducts,
  createCheckoutSession,
  getCurrentUserSubscriptions,
} from "@stripe/firestore-stripe-payments";
import { httpsCallable } from "firebase/functions";
import { collection, query, where } from "firebase/firestore";
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

const fromHex = (input) => {
  let output = "";
  for (let i = 0; i < input.length; i += 2) {
    output += String.fromCharCode(parseInt(input.substr(i, 2), 16));
  }
  return output;
};

export default function BuyLicence({ user }) {
  const app = getApp();
  const functions = useFunctions();
  const firestore = useFirestore();
  const productLicenceCollection = collection(firestore, "productLicences");
  const productLicenceQuery = query(
    productLicenceCollection,
    where("creatorId", "==", user.uid || "None")
  );
  const { status, data } = useFirestoreCollectionData(productLicenceQuery, {
    idField: "id",
  });
  const callGenerateLicence = httpsCallable(functions, "generateLicence");
  const [products, setProducts] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [buyError, setBuyError] = React.useState("");
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [processedSubs, setProcessedSubs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] =
    React.useState(false);

  const payments = getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers",
  });

  React.useEffect(() => {
    const getData = async () => {
      const response = await getCurrentUserSubscriptions(payments);
      setSubscriptions(response);
      response.forEach((element) => {
        if (element.status === "active") {
          setHasActiveSubscription(true);
        }
      });
    };
    if (payments && subscriptions.length < 1) {
      getData();
    }
  }, [payments, subscriptions]);

  React.useEffect(() => {
    const getData = async () => {
      const response = await getProducts(payments, {
        activeOnly: true,
        includePrices: true,
      });
      setProducts(response);
    };
    if (payments && products.length < 1) {
      getData();
    }
  }, [payments, products]);

  React.useEffect(() => {
    if (data && subscriptions) {
      const newSubs = [];
      subscriptions.forEach((sub) => {
        let newSub = { ...sub };
        data.forEach((d) => {
          const cust = JSON.parse(fromHex(d.licence.split("|")[0]));
          const currentDate = new Date();
          const expiryDate = new Date(cust.expires);
          if (sub.id === cust.customer) {
            newSub = {
              ...newSub,
              licence: d.licence,
              licenceExpires: cust.expires,
              licenceExpired: currentDate > expiryDate,
            };
          }
        });
        newSubs.push(newSub);
      });
      setProcessedSubs(newSubs);
    }
  }, [data, subscriptions]);

  const onClickBuyProduct = async (e, product) => {
    e.preventDefault();
    try {
      setLoading(true);
      setBuyError("");
      const session = await createCheckoutSession(payments, {
        price: product.prices[0].id,
        success_url: window.location.href,
        cancel_url: window.location.href,
        allow_promotion_codes: true,
      });
      window.location.assign(session.url);
    } catch (error) {
      console.error(error);
      setBuyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onClickGetLicence = async (e, sub) => {
    e.preventDefault();
    try {
      setLoading(true);
      setErrorMessage("");
      const response = await callGenerateLicence({ subscriptionId: sub.id });
      console.log(response);
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickCopy = (e, text) => {
    e.preventDefault();
    navigator.clipboard.writeText(text);
  };

  return (
    <Paper elevation={6} sx={{ p: 2, textAlign: "left" }}>
      <Stack spacing={1}>
        <Typography variant="h6">Subscriptions</Typography>
        {!hasActiveSubscription && (
          <TableContainer>
            <Table aria-label="pricing" size="small">
              <TableBody>
                {[
                  { title: "Managed Devices", content: "Unlimited" },
                  { title: "Managed Users", content: "Unlimited" },
                  { title: "Issued Certificates", content: "Unlimited" },
                  { title: "Device Type", content: "Yubico YubiKeys" },
                  {
                    title: "Support",
                    content:
                      "Support is provided via Email only during business hours in the AEST (Australia/Sydney) Timezone. If you require additional support, or need any professional services, please email sales@congruentlabs.co for a custom quote.",
                  },
                  {
                    title: "Price",
                    content:
                      "USD $750/EUR €690/AUD $1000 Yearly (incl. 10% GST for Australian Customers)",
                  },
                ].map((c) => (
                  <TableRow key={c.title}>
                    <TableCell>{c.title}</TableCell>
                    <TableCell>
                      <b>{c.content}</b>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {status === "loading" && <LinearProgress color="secondary" />}
        {status === "success" && (
          <Grid container>
            {processedSubs &&
              processedSubs.map((subs) => (
                <Grid item xs={12} sm={6} key={subs.id}>
                  <Card variant="outlined">
                    <CardContent sx={{ textAlign: "center" }}>
                      <Stack spacing={1}>
                        <Typography variant="body1" gutterBottom>
                          Subscription: {subs.id}
                        </Typography>
                        <Chip
                          label={`Status: ${subs.status}`}
                          color={
                            subs.status === "active" ? "primary" : "default"
                          }
                          sx={{ textTransform: "capitalize" }}
                        />
                        <Typography variant="body2">
                          {`Renewal Date: ${subs.current_period_end}`}
                        </Typography>
                        {(!subs.licence || subs.licenceExpired) && (
                          <Button
                            onClick={(e) => onClickGetLicence(e, subs)}
                            color="secondary"
                            size="small"
                            disabled={loading}
                          >
                            Generate Licence
                          </Button>
                        )}
                        {subs.licence && (
                          <Stack direction="row" spacing={2}>
                            <Chip
                              label="Licence Generated"
                              color="primary"
                              variant="outlined"
                            />
                            <Button
                              size="small"
                              color="secondary"
                              variant="contained"
                              onClick={(e) => onClickCopy(e, subs.licence)}
                              startIcon={<ContentCopyTwoToneIcon />}
                            >
                              Copy Licence
                            </Button>
                          </Stack>
                        )}
                        {errorMessage && (
                          <Alert severity="error" sx={{ textAlign: "left" }}>
                            <AlertTitle>Error Requesting Licence</AlertTitle>
                            {errorMessage}
                          </Alert>
                        )}
                        {loading && <LinearProgress color="secondary" />}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
        {products && (
          <Stack direction="row" spacing={2}>
            {products.map((p) => (
              <Button
                key={p.id}
                variant="contained"
                onClick={(e) => onClickBuyProduct(e, p)}
                disabled={loading || hasActiveSubscription}
              >
                Buy {p.name}
              </Button>
            ))}
            {subscriptions.length > 0 && (
              <Button
                component={Link}
                to={`https://billing.stripe.com/p/login/eVa03icq16DP8Qo6oo?prefilled_email=${user.email}`}
                target="_blank"
                color="secondary"
                disabled={loading}
              >
                Manage Account
              </Button>
            )}
          </Stack>
        )}
        {loading && <LinearProgress color="secondary" />}
        {buyError && (
          <Alert severity="error" sx={{ textAlign: "left" }}>
            <AlertTitle>Error Requesting Licence</AlertTitle>
            {buyError}
          </Alert>
        )}
      </Stack>
    </Paper>
  );
}
