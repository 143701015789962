import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  LinearProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useFunctions } from "reactfire";
import { httpsCallable } from "firebase/functions";
import ConstructionIcon from "@mui/icons-material/Construction";
import TrialLicences from "./TrialLicences";

export default function TrialLicence({ user }) {
  const functions = useFunctions();
  const callTrialLicence = httpsCallable(functions, "requestTrial");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [hasActiveLicence, setHasActiveLicence] = React.useState(false);

  const onClickRequestTrial = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setErrorMessage("");
      const response = await callTrialLicence();
      console.log(response);
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={6} sx={{ p: 2, textAlign: "left" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h2">
            Request Trial Licence
          </Typography>
          <Typography variant="body1" component="p">
            When you are ready to test, request a trial mFACTA licence. This
            licence will be valid for 31 days and limited to 10 devices.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading || hasActiveLicence}
            onClick={onClickRequestTrial}
            startIcon={<ConstructionIcon />}
          >
            Request Trial Licence
          </Button>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>Error Requesting Trial Licence</AlertTitle>
              {errorMessage}
            </Alert>
          </Grid>
        )}
        {loading && (
          <Grid item xs={12} textAlign="center">
            <LinearProgress variant="indeterminate" />
          </Grid>
        )}
        {user && user.uid && (
          <TrialLicences
            user={user}
            setHasActiveLicence={setHasActiveLicence}
          />
        )}
      </Grid>
    </Paper>
  );
}
