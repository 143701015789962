import React from "react";
import { Grid, Typography, Button, ButtonGroup, Paper } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import DescriptionIcon from "@mui/icons-material/Description";

export const standaloneClientInstallerUrl =
  "https://firebasestorage.googleapis.com/v0/b/signata-enterprise.appspot.com/o/Signata%20Standalone%20Client%20Setup%201.2.3.exe?alt=media&token=eb2583bb-de95-4ded-9919-da306b242377";
export const standaloneServerInstallerUrl =
  "https://firebasestorage.googleapis.com/v0/b/signata-enterprise.appspot.com/o/Signata%20Enterprise%20Standalone%20v1.2.3.exe?alt=media&token=ba865c0f-f0cb-455b-b492-bf0a19386012";
export const standaloneInstallationGuideUrl =
  "https://docs.mfacta.com/";

export default function Downloads() {
  return (
    <Paper elevation={6} sx={{ p: 2, textAlign: "left" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h3">
            Product Documentation
          </Typography>
          <Typography variant="body1">
            Please read the Installation Guide first, as there are a number of
            pre-requisites you will need to set up before you can install the
            Standalone Edition Server.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              href={standaloneInstallationGuideUrl}
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<DescriptionIcon />}
            >
              Product Documentation
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h3">
            Standalone Edition Server
          </Typography>
          <Typography variant="body1">
            Standalone Edition requires a server installed to connect to your
            Identity Source and your Public Key Infrastructure. You will need
            Administrative Privileges on any workstation that you run this
            installer on.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup>
            <Button
              variant="contained"
              color="secondary"
              href={standaloneServerInstallerUrl}
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<GetAppIcon />}
            >
              Download Server
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            Supported Operating Systems: Windows Server 2012, Windows Server
            2012 R2, Windows Server 2016, Windows Server 2019, Windows Server
            2022.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h3">
            Standalone Edition Client
          </Typography>
          <Typography variant="body1">
            Standalone Edition also requires a client to be installed to connect
            to the server and issue your devices. You will need Administrative
            Privileges on any workstation/server that you run this installer on,
            and the ability to connect your devices (YubiKeys, etc.).
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup>
            <Button
              variant="contained"
              color="secondary"
              href={standaloneClientInstallerUrl}
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<GetAppIcon />}
            >
              Download Client
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            Supported Operating Systems: Windows 8, Windows 10, Windows 11.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
