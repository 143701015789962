import React from "react";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  AuthProvider,
  FirestoreProvider,
  useFirebaseApp,
  FunctionsProvider,
} from "reactfire";
import { createTheme, Divider, Paper, ThemeProvider } from "@mui/material";
import "./App.css";
import { getFunctions } from "firebase/functions";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Body from "./components/Body";

function App() {
  const app = useFirebaseApp();
  const functions = getFunctions(app);
  const firestore = getFirestore(app);
  const auth = getAuth(app);

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#9CFF57',
      },
      secondary: {
        main: '#f50057',
      },
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestore}>
          <FunctionsProvider sdk={functions}>
            <Paper>
              <Header />
              <Divider />
              <Body />
              <Divider />
              <Footer />
            </Paper>
          </FunctionsProvider>
        </FirestoreProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
//#9CFF57
