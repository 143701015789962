import React from "react";
import { CircularProgress } from "@mui/material";
import { useAuth, useSigninCheck } from "reactfire";
import firebase from 'firebase/compat/app';
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import UserDetails from './UserDetails';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ]
}

export default function User() {
  const { status, data: signInCheckResult } = useSigninCheck();
  const auth = useAuth();


  if (status === "loading") {
    return <CircularProgress />;
  }
  if (signInCheckResult.signedIn === true) {
    return <UserDetails />;
  } else {
    return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
  }
}
