import React from "react";
import { Grid, Container, Alert, AlertTitle, Paper } from "@mui/material";
import { useSigninCheck, useUser } from "reactfire";
import User from "./User";
import TrialLicence from "./TrialLicence";
import BuyLicence from "./BuyLicence";
import Downloads from "./Downloads";
import Hero from "./Hero";
import About from "./About";

function Body() {
  const { data: signInCheckResult } = useSigninCheck();
  const { data: user } = useUser();
  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      {signInCheckResult && signInCheckResult.signedIn ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <User />
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={6}>
              <Alert severity="warning">
                <AlertTitle>
                  Signata MFA is now mFACTA
                </AlertTitle>
                Some URLs and content in the product has not yet been rebranded, so please bear with us until we finish the rebranding process.
              </Alert>
            </Paper>
          </Grid>
          {user && user.emailVerified && (
            <Grid item xs={12}>
              <Downloads />
            </Grid>
          )}
          {user && user.emailVerified && (
            <Grid item xs={12}>
              <TrialLicence user={user} />
            </Grid>
          )}
          {user && user.emailVerified && (
            <Grid item xs={12}>
              <BuyLicence user={user} />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Hero />
          <Grid item xs={12}>
            <User />
          </Grid>
          <About />
        </Grid>
      )}
    </Container>
  );
}

export default Body;
