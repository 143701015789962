import React from "react";
import { Alert, Grid, Stack, Typography } from "@mui/material";

export default function About() {
  return (
    <Grid item xs={12}>
      <Stack spacing={2}>
        <Alert severity="info">
          Signata MFA/Enterprise has been rebranded to mFACTA
        </Alert>
        <Typography variant="body1" textAlign="left">
          mFACTA uses your existing infrastructure to issue your YubiKeys.
          mFACTA will connect to your Active Directory to get your user
          information, and it will use your Active Directory Certificate
          Services PKI to get certificates to set up the PIV parts of your
          YubiKeys.
        </Typography>
        <img
          src="graphic-1.png"
          alt="Provisioning Devices"
          sx={{ maxWidth: 50 }}
        />
        <Typography variant="body1" textAlign="left">
          The certificates are loaded into your YubiKeys, the YubiKeys are
          security hardened, and they can be then be used to log on securely to
          workstations, virtual private networks, remote access services,
          corporate web sites, and more!
        </Typography>
        <Typography variant="body1" textAlign="left">
          mFACTA currently supports Yubico YubiKeys (with PIV enabled), and will
          support more device types soon. The server and desktop client software
          are Windows only. You can install as many client or server instances
          that you need.
        </Typography>
        <Typography variant="body1" textAlign="left">
          mFACTA is free to try for one month and up to 10 devices. Buy an
          unlimited site licence for USD $750/EUR €690/AUD $1000 Yearly (incl.
          10% GST for Australian Customers).
        </Typography>
      </Stack>
    </Grid>
  );
}
