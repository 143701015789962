import React from 'react';
import ReactDOM from 'react-dom/client';
import { FirebaseAppProvider } from 'reactfire';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const firebaseConfig = {
  apiKey: 'AIzaSyCSJAYLR2qVTP_2eboUS_WiDZsgIbYDwEk',
  authDomain: 'signata-enterprise.firebaseapp.com',
  databaseURL: 'https://signata-enterprise.firebaseio.com',
  projectId: 'signata-enterprise',
  storageBucket: 'signata-enterprise.appspot.com',
  messagingSenderId: '944796805205',
  appId: '1:944796805205:web:7ce954ea5b528b64000cdc',
  measurementId: 'G-DCV5JRV3LT',
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </FirebaseAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
