import React from "react";
import {
  Grid,
  Typography,
  Box,
} from "@mui/material";

export default function Hero() {
  return (
    <Grid item xs={12}>
      <Box style={{ py: "40px", textAlign: "center" }}>
        <img alt="Signata Logo" src="logo.png" style={{ maxHeight: "200px" }} />
        <Typography variant="h2" component="h1" style={{ fontWeight: "bold" }}>
          <i>mFACTA</i>
        </Typography>
        <Typography variant="h5" component="h2">
          YubiKey PIV Certificate Management for Businesses
        </Typography>
      </Box>
    </Grid>
  );
}
