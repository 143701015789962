import React from "react";
import { Alert, Button, AlertTitle, CircularProgress, Grid } from "@mui/material";
import { useAuth, useUser } from "reactfire";
import { sendEmailVerification } from "firebase/auth";

export default function UserDetails() {
  const { status, data: user } = useUser();
  const auth = useAuth();

  const onClickLogOut = async (e) => {
    e.preventDefault();
    await auth.signOut();
  }
  const onClickVerify = async (e) => {
    e.preventDefault();
    await sendEmailVerification(user);
  }

  return (
    <>
      {status === "loading" && (
        <CircularProgress color="secondary" />
      )}
      {status === "error" && (
        <Alert severity="error">
          <AlertTitle>
            Error Loading User!
          </AlertTitle>
          Something went wrong loading your login details. Please contact support.
        </Alert>
      )}
      {status === "success" && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Alert severity="success" color="info" action={
              <Button onClick={onClickLogOut} size="small" color="inherit">Log Out</Button>
            }>
              Logged in as: {user.email}
            </Alert>
          </Grid>
          {!user.emailVerified && (
            <Grid item xs={12}>
              <Alert severity="warning" action={
                <Button onClick={onClickVerify} size="small" color="inherit">Resend Verification</Button>
              }>
                Please verify your email address to use mFACTA
              </Alert>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}
