import React from "react";
import {
  Alert,
  LinearProgress,
  Grid,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, where } from "firebase/firestore";
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

const fromHex = (input) => {
  // log.debug('fromHex');
  let output = "";
  for (let i = 0; i < input.length; i += 2) {
    output += String.fromCharCode(parseInt(input.substr(i, 2), 16));
  }
  return output;
};

export default function TrialLicences({ user, setHasActiveLicence }) {
  const firestore = useFirestore();
  const trialLicenceCollection = collection(firestore, "trialLicences");
  const trialLicenceQuery = query(
    trialLicenceCollection,
    where("creatorId", "==", user.uid || "None")
  );
  const { status, data } = useFirestoreCollectionData(trialLicenceQuery, {
    idField: "id",
  });

  React.useEffect(() => {
    if (data && data.length > 0) {
      const currentDate = new Date();
      data.forEach((d) => {
        const jsonString = fromHex(d.licenceData);
        const licenceObj = JSON.parse(jsonString);
        const dateObj = new Date(licenceObj.expires);
        if (dateObj > currentDate) {
          setHasActiveLicence(true);
        }
      });
    }
  }, [data, setHasActiveLicence]);

  const onClickCopy = (e, text) => {
    e.preventDefault();
    navigator.clipboard.writeText(text);
  };

  return (
    <Grid item xs={12}>
      {status === "loading" && <LinearProgress variant="indeterminate" />}
      {status === "error" && (
        <Alert severity="error">Error Retrieving Trial Licences</Alert>
      )}
      {status === "success" && data.length < 1 && (
        <Alert severity="info">No Trial Licences Requested Yet</Alert>
      )}
      {status === "success" && data.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Devices</TableCell>
                <TableCell>Customer</TableCell>
                {/* <TableCell>Device Types</TableCell> */}
                <TableCell>Expires</TableCell>
                <TableCell>Licence</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((trialLicence) => (
                <TableRow key={trialLicence.id}>
                  <TableCell>
                    {JSON.parse(fromHex(trialLicence.licenceData)).devices}
                  </TableCell>
                  <TableCell>
                    {JSON.parse(fromHex(trialLicence.licenceData)).customer}
                  </TableCell>
                  {/* <TableCell>
                    {JSON.parse(fromHex(trialLicence.licenceData)).deviceTypes}
                  </TableCell> */}
                  <TableCell>
                    {JSON.parse(fromHex(trialLicence.licenceData)).expires}
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={(e) => onClickCopy(e, trialLicence.trialLicence)}
                      startIcon={<ContentCopyTwoToneIcon />}
                    >
                      Copy Licence
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
}
