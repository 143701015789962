import React from "react";
import { AppBar, Container, Toolbar, Typography } from "@mui/material";

const Header = () => (
  <AppBar position="sticky">
    <Container maxWidth="md">
      <Toolbar>
        <img
          src="logo.png"
          alt="mFACTA Logo"
          style={{ maxWidth: 48, paddingRight: 8 }}
        />
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr: 2, fontWeight: 700, color: "inherit" }}
        >
          <i>mFACTA</i>
        </Typography>
      </Toolbar>
    </Container>
  </AppBar>
);

export default Header;
