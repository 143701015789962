import React from "react";
import { AppBar, Container, Link, Toolbar } from "@mui/material";

const Footer = () => (
  <AppBar
    position="static"
    sx={{ top: "auto", bottom: 0, backgroundColor: "black", pt: 4, pb: 4 }}
  >
    <Container maxWidth="md">
      <Toolbar variant="dense">
        <img
          src="logo.png"
          alt="mFACTA Logo"
          style={{ maxWidth: 24, paddingLeft: 4, paddingRight: 4 }}
        />
        <Link
          variant="overline"
          component="a"
          href="https://congruentlabs.co"
          target="_blank"
          sx={{ textDecoration: 'none' }}
          color="inherit"
        >
          mFACTA &copy; {new Date().getFullYear()} Congruent Labs Pty Ltd
        </Link>
      </Toolbar>
    </Container>
  </AppBar>
);

export default Footer;
